// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery");
require("jquery-ui");
require("popper.js");
require("bootstrap");
require("i18n");
import $ from 'jquery'
window.jQuery = $;
window.$ = $; // lazy fix if you want to use jQuery in your inline scripts.

$(function() {
  if ($('.modal-body').text().trim().length) {
    $('#modal').modal();
  }

  var reg = $('.img-box-reg');
  reg.hover(function () {
    $('.img-box-reg').removeClass('active');
  });
  reg.click(function (e) {
    e.preventDefault();
    send_ga_event('Category Click ' + $(this).data('category'), $(this).data('label'));
    window.location = $(this).attr('href');
  });
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    var target = $.attr(this, 'href');
    if (target.length > 1){
      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
      }, 800);
    }
  });
  function send_ga_event(category, label) {
    if (typeof (gtag) !== 'undefined') {
      gtag('event', 'click', {
        'event_category': category,
        'event_label': label
      });
    }
  }
});
